import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  TestIdPartial,
  AccessibilityLabelPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/rn-floating-action.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        disableCodeEditing
        code={snippet}
        platform="reactnative"
        gitHubLink="buttons/button-row"
        layout="rn"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="icon" types={['element']} isRequired>
            <Text>The icon element to show in the floating action.</Text>
          </PropListItem>

          <PropListItem name="isDisabled" types={['boolean']}>
            <Text>Determines whether the floating action is disabled.</Text>
          </PropListItem>

          <PropListItem name="size" types={['string']}>
            <Text>Determines the size of the floating action.</Text>
            <List type="unordered">
              <li>
                <code>medium</code>
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onPress" types={['() => void']} isRequired>
            <Text>Responds to the floating action being pressed.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <AccessibilityLabelPartial componentName="floating action" />

          <TestIdPartial componentName="floating action" />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from a{' '}
              <Link href="https://reactnative.dev/docs/touchableopacity">
                touchable opacity
              </Link>{' '}
              component.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Floating Action Button"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
